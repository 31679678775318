<template>
  <div class="text-center">
    <form-wizard
      @on-complete="onComplete"
      color="#1e88e5"
      title="Form for Job Interview"
      subtitle="For Web Designer"
      back-button-text="Go Previous"
      next-button-text="Go Next"
      finish-button-text="We're Here"
    >
      <tab-content title="Personal details" icon="mdi mdi-account">
        <span class="font-weight-bold">My first tab content</span>
      </tab-content>
      <tab-content title="Reuirements" icon="mdi mdi-account-settings-variant">
        <span class="font-weight-bold">My second tab content</span>
      </tab-content>
      <tab-content title="Last step" icon="mdi mdi-account-star">
        <span class="font-weight-bold"
          >Yuhuuu! This seems pretty damn simple</span
        >
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

export default {
  name: "FormWizardCustomText",
  data: () => ({
    title: "FormWizardCustomText",
  }),
  components: {
    FormWizard,
    TabContent,
  },
  methods: {
    onComplete: function () {
      alert("Yay. Done!");
    },
  },
};
</script>